import React from "react";
import { Link } from "react-router-dom";
import { DiAndroid, DiApple } from "react-icons/di";

const PortfolioItem = ({ portfolio }) => {
  return (
    <div className="rwt-card">
      <div className="inner">
        <div className="thumbnail">
          <figure className="card-image">
            <Link to={`/project-details/${portfolio.id}`}>
              <img
                className="img-fluid"
                src={portfolio.portfolioImage}
                alt="Portfolio-01"
              />
            </Link>
          </figure>
        </div>

        <div
          style={{ display: "flex", justifyContent: "space-between" }}
          className="content"
        >
          <div>
            <h5 className="title mb--10" style={{ margin: "1px" }}>
              {portfolio.title}
            </h5>
            <span className="subtitle b2 text-capitalize">
              {portfolio.category}
            </span>
          </div>

          <div style={{ marginRight: "1rem" }} className="iconContainer">
            {/* Conditionally render Apple icon if the link is available */}
            {portfolio?.appleStoreLink && (
              <Link to={`${portfolio.appleStoreLink}`} target="_blank">
                <DiApple style={{ fontSize: "3rem" }} />
              </Link>
            )}

            {/* Conditionally render Android icon if the link is available */}
            {portfolio?.androidStoreLink && (
              <Link to={`${portfolio.androidStoreLink}`} target="_blank">
                <DiAndroid style={{ fontSize: "2.5rem" }} />
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PortfolioItem;
