import React from "react";
import Slider from "react-slick";
import { slickDot } from "../../utils/script";
import { useFirestore } from "../../context/FirestoreContext";

const TestimonialData = [
  {
    form: "Poland",
    description:
      "Excellent Developer. He provide the high quality code excellent communication with us. Would like to work with him again. Thanks to SHahzad for doing my work proper.",
    name: "Anastasia Naval",
    subtitle: "App development",
    image: "testimonial-01",
  },
  {
    form: "Germany",
    description:
      "Thanks to Shahzad for work completion successfully. During work process freelancer was polite and demonstrated high level of proficiency in mobile app dev React Native. we was working long time by which I got project done successfully. English is fluent and i plan to work with Ali in future. Thanks!",
    name: "Stefan Creadore",
    subtitle: "App development",
    image: "testimonial-02",
  },
  {
    form: "USA",
    description:
      "I enjoyed working with CodeHunk Technology, they were polite, and showed professional skills during project implementation contract time. I will continue work with them in future through upwork and recommend them as qualified developer with proven experience. The work has been done successfully.",
    name: "Didar Kamelivon",
    subtitle: "App development",
    image: "testimonial-03",
  },
  {
    form: "USA",
    description:
      "CodeHunk Technology’s Dev was quick to identify flaws in the existing implementation and suggested ways to improve the architecture of the application in addition to completing the tasks assigned to them. I would highly recommend them for any React Native project.",
    name: "Martin Alaskar",
    subtitle: "App development",
    image: "testimonial-10",
  },
  {
    form: "USA",
    description:
      "One of the best contractors I have ever worked with.Working with CodeHunk Technology and will likely have additional jobs for them in the future Highly recommend them, worked on a project deliver and communicated in great fashion I enjoyed .”",
    name: "Anton Donodov.",
    subtitle: "App development",
    image: "testimonial-05",
  },
  {
    form: "USA",
    description:
      "Thanks to Shahzad for work completion successfully. During work process freelancer was polite and demonstrated high level of proficiency in mobile app dev React Native. we was working long time by which I got project done successfully. English is fluent and i plan to work with Ali in future. Thanks!.",
    name: "Stefan Creadore",
    subtitle: "App development",
    image: "testimonial-06",
  },
  {
    form: "USA",
    description:
      "'They were great mobile developers! Good knowledge of React Native and mobile development in general. Listens and understands the problem very well, good communication skills! Highly recommend for a new mobile project.",
    name: "Bryan Hackett",
    subtitle: "App development",
    image: "testimonial-07",
  },
  {
    form: "Canada",
    description:
      "'I had a great experience with Ali, his work was excellent and he went the extra mile to work on weekends and nights to meet deadlines. Will work with him again Thanks",
    name: "Connor Paddon",
    subtitle: "App development",
    image: "testimonial-09",
  },
  {
    form: "Germany",
    description:
      "'Web Development,MD has been fast and accommodating throughout the development. His skills in Wordpress, Buddypress, PHP, JQuery and CSS is impressive. Working across timezones, he's been flexible to make sure we have regular feedback cycles and are kept in the loop on progress. We're happy with the result of the work and would highly recommend him.",
    name: "Matteo Secchi",
    subtitle: "Web development",
    image: "testimonial-11",
  },
  {
    form: "USA",
    description:
      "'One of the best contractors I have ever worked with. Highly recommend him, worked on a project deliver and communicated in great fashion.",
    name: "Ahmed Alshamary",
    subtitle: "App development",
    image: "testimonial-08",
  },
];

const TestimonialThree = ({ teamStyle }) => {
  const slicetext = (str) => {
    str = str.split(" ");
    let mystr = "";
    for (let i = 0; i < 16; i++) {
      mystr += str[i] + " ";
    }
    return `${mystr}...`;
  };

  return (
    <div className="row">
      <div className="col-lg-12">
        <Slider
          className="slick-space-gutter--15 rn-slick-dot rn-slick-arrow mb--60"
          {...slickDot}
        >
          {TestimonialData?.map((data, index) => (
            <div key={index} className={`testimonial-style-two ${teamStyle}`}>
              <div className="row align-items-center row--20">
                <div className="order-2 order-md-1 col-lg-6 col-md-8 offset-lg-1">
                  <div className="content mt_sm--40">
                    <span className="form">{data.form}</span>
                    <p className="description">{slicetext(data.description)}</p>
                    <div className="client-info">
                      <h4 className="title">{data.name}</h4>
                      <h6 className="subtitle">{data.subtitle}</h6>
                    </div>
                  </div>
                </div>
                <div className="order-1 order-md-2 col-lg-4 col-md-4">
                  <div className="thumbnail">
                    <img
                      className="w-100"
                      src={`./images/testimonial/${data.image}.jpg`}
                      alt="CodeHunk Technology testimonial "
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};
export default TestimonialThree;
